import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "status": "published",
  "path": "/replace-text-on-webpage/",
  "heroImage": "code.jpg",
  "title": "Replace All Text on a Webpage",
  "description": "Loop through a webpage and replace the text",
  "date": "2020-05-06T00:00:00.000Z",
  "tags": ["code"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Sidenote = makeShortcode("Sidenote");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`There have been a number of times where I was looking to quickly replace either a word or text within in an element on a webpage, sometimes for work but mainly just for fun. I have found a number of ways to accomplish this by using some quick javascript snippets in the console.`}</p>
    <h4>{`Select the first h4 element on the page and replace text`}</h4>
    <ol>
      <li parentName="ol">{`Open the Console in the Developer Tools (Chrome)`}</li>
    </ol>
    <Sidenote type="tip" color="var(--color-tip)" background="var(--color-tip-background)" title="Keyboard Shortcut" content="<div>Mac: Option + CMD + J<br> Win: Shift + CTRL + J</div>" mdxType="Sidenote" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Copy Javascript snippet below`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`innerHTML `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"The first h4 text on the page has been replaced"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Paste it into the Console and hit return`}</li>
    </ol>
    <p>{`Now check the first h4 element on the page. What used to be `}<strong parentName="p">{`"Select the first h4 element on the page and replace text
"`}</strong>{` has now been replaced with the "The first h4 text on the page has been replaced".`}</p>
    <h4>{`Select All h4 Elements and Replace Text With `}<code parentName="h4" {...{
        "className": "language-text"
      }}>{`Array.forEach()`}</code></h4>
    <p>{`That was pretty cool, but what if you want to replace all h4 elements on the page at once. We can use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`forEach`}</code>{` method to grab all h4s on the page and replace their text.`}</p>
    <ol>
      <li parentName="ol">{`Open the Console in the Developer Tools`}</li>
      <li parentName="ol">{`Copy Javascript snippet below`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`innerHTML `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"All h4 text should be replaced on the page"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Paste it into the Console and hit return`}</li>
    </ol>
    <p>{`Now check all the h4 elements on the page. The previous h4 text should now all be replaced with "All h4 text should be replaced on the page".`}</p>
    <h4>{`Select All h4 Elements and Replace Text With a FOR LOOP`}</h4>
    <p>{`What if you want to use a good ol' FOR loop to replace all h4 elements on the page at once. Let's do it.`}</p>
    <ol>
      <li parentName="ol">{`Open the Console in the Developer Tools`}</li>
      <li parentName="ol">{`Copy Javascript snippet below`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` i`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`++`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`innerHTML `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Replaced by a for loop"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Paste it into the Console and hit return`}</li>
    </ol>
    <h4>{`Select All h4 Elements and Replace Text With `}<strong parentName="h4">{`Array.prototype.map()`}</strong></h4>
    <p>{`Let's try the map method. First we convert the array-like or iterable object into an array with the `}<strong parentName="p">{`Array.from()`}</strong>{` method. Then we'll use `}<strong parentName="p">{`Array.prototype.map()`}</strong>{` to create a new array populated with every h4 on the page.`}</p>
    <ol>
      <li parentName="ol">{`Open the Console in the Developer Tools`}</li>
      <li parentName="ol">{`Copy Javascript snippet below`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`Array`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`from`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`innerHTML `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Replaced by the map method"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Paste it into the Console and hit return`}</li>
    </ol>
    <h4 data-hierarchy="pick-me"> Select Element by Data Attribute and Replace Text</h4>
    <p>{`Just to practice selecting by data attribute, let's try the snippet below.`}</p>
    <ol>
      <li parentName="ol">{`Open the Console in the Developer Tools`}</li>
      <li parentName="ol">{`Copy Javascript snippet below`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h4[data-hierarchy='pick-me']"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`innerHTML `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Selected by data attribute and text replaced"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Paste it into the Console and hit return`}</li>
    </ol>
    <Sidenote type="warning" color="var(--color-warning)" background="var(--color-warning-background)" title="Warning" content="Be sure to use single quotes when selecting by data attribute inside double quotations" mdxType="Sidenote" />
    <h4>{`Quick Browser Console Hack`}</h4>
    <p>{`Copy and paste this code into the Console of someone's browser and hit return. Watch the panic that ensues when they click on an element on the web page.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` allElements `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h1, h2, h3, h4, h4, p, a, span, submit, img"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` textElements `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h1, h2, h3, h4, h4, p, span"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` images `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"img, source"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` backgroundImages `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"div"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

allElements`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
  x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"click"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// prevent links and submit button from performting their default actions`}</span>{`
    x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`preventDefault`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// replace text for each element in the text array`}</span>{`
    textElements`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`innerHTML `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Gotcha!"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// replace src and srcset for each img in the images array`}</span>{`
    images`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`src `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://res.cloudinary.com/ndub/image/fetch/v1540505745/https://www.sciencealert.com/images/articles/processed/DogPoop_1024.jpg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`srcset `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://res.cloudinary.com/ndub/image/fetch/v1540505745/https://www.sciencealert.com/images/articles/processed/DogPoop_1024.jpg"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`style`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`objectFit `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cover"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// replace background image for each div in the array of divs selected`}</span>{`
    backgroundImages`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`style`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`backgroundImage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`style`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`backgroundImage `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`
          `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"url(https://res.cloudinary.com/ndub/image/fetch/v1540505745/https://www.sciencealert.com/images/articles/processed/DogPoop_1024.jpg)"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`style`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`backgroundSize `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cover"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`style`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`backgroundPosition `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"center"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      